var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var errors = ref.errors;
return [_c('form',{staticClass:"login",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('div',{staticClass:"ym-toptitle-box"},[_c('fixed-header',{attrs:{"threshold":119,"fixedClass":"ym-toptitle-fixed"}},[_c('div',{staticClass:"level ym-toptitle",attrs:{"id":"toptitle"}},[_c('div',{staticClass:"level-left"},[_c('router-link',{staticClass:"button xbtn is-medium is-transparent",attrs:{"tag":"a","to":"/records/machinery"}},[_c('icon',{attrs:{"name":"x"}})],1),(_vm.isNew)?_c('h2',{staticClass:"title"},[_vm._v("Nowa maszyna")]):_c('h2',{staticClass:"title"},[_vm._v("Edytuj maszyne")])],1),_c('div',{staticClass:"buttons level-right"},[_c('button',{staticClass:"button is-medium is-primary"},[_c('icon',{attrs:{"name":"check"}}),_c('span',[_vm._v("Zapisz")])],1)])])])],1),_c('div',{class:errors['name'] && errors['name'].length > 0
            ? 'columns is-variable is-6 is-multiline v-field v-error'
            : 'columns is-variable is-6 is-multiline'},[_c('div',{staticClass:"column ym-column is-4"},[_c('div',{staticClass:"ym-label"},[_c('label',{attrs:{"for":"f_company_short"}},[_vm._v("Nazwa")]),(errors['name'] && errors['name'].length > 0)?_c('span',{staticClass:"help is-danger"},[_vm._v(_vm._s(errors["name"][0]))]):_vm._e()])]),_c('div',{staticClass:"column ym-column is-8"},[_c('div',{staticClass:"field"},[_c('div',{staticClass:"control is-medium"},[_c('ValidationProvider',{attrs:{"rules":"required","name":"name"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.machinery.name),expression:"machinery.name"}],staticClass:"input is-medium is-uppercase",attrs:{"name":"name"},domProps:{"value":(_vm.machinery.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.machinery, "name", $event.target.value)}}})])],1)])])]),_c('div',{class:errors['type'] && errors['type'].length > 0
            ? 'columns is-variable is-6 is-multiline v-field v-error'
            : 'columns is-variable is-6 is-multiline'},[_c('div',{staticClass:"column ym-column is-4"},[_c('div',{staticClass:"ym-label"},[_c('label',{attrs:{"for":"f_company_short"}},[_vm._v("Typ")]),(errors['type'] && errors['type'].length > 0)?_c('span',{staticClass:"help is-danger"},[_vm._v(_vm._s(errors["type"][0]))]):_vm._e()])]),_c('div',{staticClass:"column ym-column is-8"},[_c('div',{staticClass:"field"},[_c('div',{staticClass:"control is-medium"},[_c('ValidationProvider',{attrs:{"rules":"required","name":"type"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.machinery.type),expression:"machinery.type"}],staticClass:"input is-medium is-uppercase",attrs:{"name":"type"},domProps:{"value":(_vm.machinery.type)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.machinery, "type", $event.target.value)}}})])],1)])])]),_c('div',{class:errors['serial'] && errors['serial'].length > 0
            ? 'columns is-variable is-6 is-multiline v-field v-error'
            : 'columns is-variable is-6 is-multiline'},[_c('div',{staticClass:"column ym-column is-4"},[_c('div',{staticClass:"ym-label"},[_c('label',{attrs:{"for":"f_company_short"}},[_vm._v("Numer seryjny")]),(errors['serial'] && errors['serial'].length > 0)?_c('span',{staticClass:"help is-danger"},[_vm._v(_vm._s(errors["serial"][0]))]):_vm._e()])]),_c('div',{staticClass:"column ym-column is-8"},[_c('div',{staticClass:"field"},[_c('div',{staticClass:"control is-medium"},[_c('ValidationProvider',{attrs:{"name":"serial"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.machinery.serial),expression:"machinery.serial"}],staticClass:"input is-medium is-uppercase",attrs:{"name":"serial"},domProps:{"value":(_vm.machinery.serial)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.machinery, "serial", $event.target.value)}}})])],1)])])])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }